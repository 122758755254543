import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export const TermsContainer = ({ children }: Props): JSX.Element => (
  <div className="w-4/6 lg:w-3/6 mx-auto">{children}</div>
);

export const HeaderTextContainer = ({ children }: Props): JSX.Element => (
  <div className="flex flex-col pb-16">{children}</div>
);
