import React from 'react';
import Layout from '@/components/_layout';
import Seo from '@/components/_layout/seo';
import { graphql, PageProps } from 'gatsby';
import PageBanner from '@/components/teams-page-banner';
import { TermsContent } from '@/components/terms-and-conditions-content';
import { getTitle } from '@/utils/helper/getTitle';

const Terms = ({ data, location, pageContext }: PageProps<any>): JSX.Element => {
  const {
    // @ts-ignore
    breadcrumb: { crumbs },
  } = pageContext;

  return (
    <Layout location={location} crumbs={crumbs}>
      <Seo
        title={data?.sanityTermsPageMetadata?.terms_page_metadata?.pageMetaName || 'Terms'}
        description={data?.sanityTermsPageMetadata?.terms_page_metadata?.pageMetaDescription}
        pageMetadata={data?.sanityTermsPageMetadata?.terms_page_metadata?.metadata}
        canonical={data?.sanityTermsPageMetadata?.terms_page_metadata?.canonical}
      />
      <PageBanner
        backgroundImageUrl={data?.TERMS_PAGE_BANNER}
        title={{ solid: 'Terms & Conditions' }}
        isAddress={false}
        isOverlay={false}
      />
      <TermsContent title={getTitle(data?.sanityTerms)} body={data?.sanityTerms?._rawDetails} />
    </Layout>
  );
};

export default Terms;

export const query = graphql`
  query {
    sanityTermsPageMetadata {
      terms_page_metadata {
        metadata {
          metaContent
          metaName
        }
        canonical
        pageMetaDescription
        pageMetaName
      }
    }
    sanityTerms {
      id
      title {
        title_solid
        title_outlined
        is_outline_first
      }
      _rawDetails
    }
    TERMS_PAGE_BANNER: file(relativePath: { eq: "award-overlay.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
