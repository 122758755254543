import React, { ReactNode } from 'react';
import { HeaderText, PageSection } from '@/components/_root';
import { HeaderTextContainer, TermsContainer } from './styled';
import BlockContent from '@/components/block-content';
interface Props {
  title?: { solid?: string; outlined?: string; isOutlinedFirst?: boolean };
  body: ReactNode;
}

export const TermsContent = ({ title, body }: Props): JSX.Element => {
  return (
    <PageSection paddingAmount="small">
      <TermsContainer>
        {!!title && (
          <HeaderTextContainer>
            <HeaderText mode="light" className={title?.isOutlinedFirst ? 'order-2' : ''}>
              {title?.solid}
            </HeaderText>
            <HeaderText.Outline mode="light">{title?.outlined}</HeaderText.Outline>
          </HeaderTextContainer>
        )}
        <BlockContent blocks={body} />
      </TermsContainer>
    </PageSection>
  );
};
